<template>
  <div>
    <HeadImage
      :src="product['image_url']"
      contain
      :action="recentAction"
      :map-zone="product['map_zone']" />

    <div class="c-section c-section--mb24">
      <h1 class="c-title4">
        {{ product['name'] }}
      </h1>

      <p class="c-lead">
        {{ product['description'] }}
      </p>

      <ul v-if="event.title || event.at" class="c-list c-list--grey01">
        <li v-if="event.at" class="c-list__item">
          <Icon name="clock" />
          <span>
            {{ $utilities.localizeDate(event.at) }}
          </span>
        </li>

        <li v-if="event.title" class="c-list__item">
          <Icon name="pin" />
          <span>
            {{ event.title }}
          </span>
        </li>
      </ul>
    </div>
    <!-- /.c-section -->

    <div v-if="product.baristas.length" class="c-section c-section--mb32">
      <h3 class="c-title6">このコーヒーの提供者</h3>

      <ProCard
        :content="product['baristas']"
        :description="product['barista_message']" />
    </div>
    <!-- /.c-section -->

    <!-- #UCC_APP-1026 -->
    <!-- <div v-if="product.ingredients.length" class="c-section c-section--mb32">
      <h3 class="c-title6">使用しているコーヒー豆</h3>

      <CoffeeCard
        v-for="(coffee, i) in ingredients.product"
        :key="i"
        :href="$customUrlScheme.page('ec', `url=${coffee['ec_link_url']}`)"
        :src="coffee['image_url']"
        :title="coffee.name" />

      <ul class="c-list2">
        <li
          v-for="(coffee, i) in ingredients.free"
          :key="i"
          class="c-list2__item">
          <Icon name="pack" />
          <span>
            {{ coffee.name }}
          </span>
        </li>
      </ul>
    </div> -->
    <!-- /.c-section -->

    <MemoForm
      :memo="memo"
      :editable="editable"
      @toggle="toggleFormType"
      @update="$emit('update', $event)" />
  </div>
</template>

<script>
export default {
  components: {
    MemoForm: () => import('@/components/coffeeHistoryDetail/MemoForm.vue')
  },

  props: {
    impression: {
      type: Object,
      required: true
    },

    product: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editable: false
  }),

  computed: {
    /**
     * @returns {{ [key: string]: number | null; }}
     */
    memo() {
      const memo = this.impression.memo;
      return {
        ...memo,
        aroma: memo?.aroma || null,
        sweetness: memo?.sweetness || null,
        sourness: memo?.sourness || null,
        bitterness: memo?.bitterness || null,
        richness: memo?.richness || null
      };
    },

    /**
     * 使用しているコーヒー豆
     */
    ingredients() {
      // 統合製品
      const product = this.product.ingredients.filter((item) => item.id);
      // 自由入力（=idが無いもの）
      const free = this.product.ingredients.filter((item) => !item.id);
      return {
        product,
        free
      };
    },

    recentAction() {
      if (!this.impression) return;

      const at = this.impression.created_at;
      const isFavorable = this.impression.is_favorable;
      const type =
        isFavorable === null ? 'memo' : isFavorable ? 'like' : 'hate';

      return { type, at };
    },

    event() {
      return {
        id: this.impression?.memo?.event?.id,
        title: this.impression?.memo?.event?.title,
        at: this.impression?.memo?.event?.at
      };
    }
  },

  methods: {
    toggleFormType() {
      this.editable = !this.editable;
    }
  }
};
</script>
